import { FunctionComponent, useContext, useEffect, useState } from "react";
import Title from "./Title.js";

import styles from "./JamHome.module.css";
import { JamUserContext } from "./Router";
import { CodeExample, FiddleLanguage, FIDDLE_LANGUAGES, FRIENDLY_LANGUAGE_METADATA } from "@fastly-fiddle/common";
import Icon from "./Icon";

const JamAuth: FunctionComponent = () => {
  return (
    <div className={styles.authBox}>
      <h2>Please run the following command in any channel in Slack to sign up for the jam.</h2>
      <kbd>/fiddle-ops jam-signup</kbd>
    </div>
  );
};

const JamList: FunctionComponent = () => {
  const [examples, setExamples] = useState<Record<string, CodeExample> | null>(null);

  useEffect(() => {
    if (examples) return;

    fetch("/jam/examples")
      .then((res) => res.json())
      .then((data) => {
        setExamples(data);
      });
  }, []);

  if (!examples) {
    return <p>Loading code examples...</p>;
  }

  const stateForExampleImplementation = (example: CodeExample, lang: FiddleLanguage) => {
    if (example.implementations[lang] === "implemented") {
      return "Implemented";
    } else if (example.implementations[lang] === "not-possible") {
      return "Impossible";
    } else {
      return <a href={`/new/${lang}?codeExamplePath=${btoa(example.path)}&title=${encodeURIComponent(example.title)}`} className="button" target="_blank" rel="noreferrer"><Icon type={`language-${lang}`} /> Start</a>;
    }
  };

  const entries =
    examples &&
    Object.values(examples).sort((a, b) => Object.values(b.implementations).filter((v) => v === "unimplemented").length - Object.values(a.implementations).filter((v) => v === "unimplemented").length).map((example) => {
      return (
        <tr key={example.path}>
          <td className={styles.title}>
            <a href={`https://fastly.dev${example.path}`}>
              <h1>{example.title}</h1>
            </a>
            <p>{example.summary}</p>
          </td>
          {FIDDLE_LANGUAGES.map((lang) => {
            return <td className={styles.implementation} data-language={lang} key={`${example.path}${lang}`}>{stateForExampleImplementation(example, lang)}</td>;
          })}
        </tr>
      );
    });

  return (<>
    <br/>
    <table className={styles.examples}>
      <thead>
        <tr>
          <th></th>
          {FIDDLE_LANGUAGES.map((lang) => (
            <th key={lang}>{FRIENDLY_LANGUAGE_METADATA[lang].shortName || FRIENDLY_LANGUAGE_METADATA[lang].name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className={styles.custom}>
          <td className={styles.title}>
            <h1>Your own example</h1>
            <p>Build a brand new example without any constraints.</p>
          </td>
          {FIDDLE_LANGUAGES.map((lang) => {
            return <td className={styles.implementation} data-language={lang} key={`new${lang}`}><a href={`/new/${lang}`} className="button"><Icon type={`language-${lang}`} /> Start</a></td>;
          })}
        </tr>
        {entries}
      </tbody>
    </table>
    <br/>
    <p>Spotted a problem with the above list? <a href="https://fastly.enterprise.slack.com/archives/CQMDPFJ1Y">Let us know.</a></p>
    <br/>
  </>);
};

const JamHome: FunctionComponent = () => {
  const jamUser = useContext(JamUserContext);

  return (
    <>
      <Title />

      <div className="container page">
        <h1>{jamUser ? <><strong>Hi @{jamUser.name}</strong>, welcome to the Summer 2024 Fiddle Jam.</> : "Welcome to the Summer 2024 Fiddle Jam"}</h1>
        <div className={styles.banner}>
          <blockquote className={styles.info}>
            <p>
              This is a no-pressure creative outlet for those who would like to contribute to Fastly but may not have the capacity for
              "normal" work right now.
            </p>
            <p>
              In contrast with previous code example contribution drives, we will not be publishing a leaderboard, as we do not feel that this
              is the right time to spark competition. However, we really appreciate any and all contributions and will be sure to express
              our gratitude directly.
            </p>
            {jamUser ? (
              <p>Below is a list of code examples on the Fastly website that are missing implementations in one or more languages. Select one <em>or</em> create your own example from scratch, then click the "Submit to jam" button in the editor when you're done. We'll review your code and publish it as soon as possible.</p>
            ) : (
              <p>Follow the instructions below to sign in, so we can attribute contributions to you.</p>
            )}
            <p className={styles.attribution}>
              &mdash; <a href="https://fastly.enterprise.slack.com/archives/CQMDPFJ1Y">#team-developer-relations</a>
            </p>
          </blockquote>
          <img src="/images/jam.png" width={300} height={300} alt={"A cute jam jar with a smiley face, labelled 'import jam from kitchen', holding a spoon of jam."} />
        </div>
        <br />
        {jamUser === false ? <JamAuth /> : <JamList />}
      </div>
    </>
  );
};

export default JamHome;
